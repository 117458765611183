import React from 'react';

import {
    Text,
    Container,
    Heading,
    className as cls,
} from '@solublestudio/react-bootstrap-components';

import ButtonComponent from '../ButtonComponent';

import style from './style.module.scss';

const BannerCTA = ({ title, cta, text, variant = 'primary', className }) => {
    return (
        <section {...cls(className)}>
            <Container>
                <div
                    className={[
                        style.banner,
                        variant === 'secondary' && style.secondary,
                    ]
                        .filter(Boolean)
                        .join(' ')}>
                    <div>
                        <Heading
                            tag="h6"
                            className={`h6 font-weight-normal ${style.title}`}>
                            {title}
                        </Heading>
                        {!!text && (
                            <Text
                                className={`caption ${style.text}`}
                                tag={'span'}>
                                {text}
                            </Text>
                        )}
                    </div>
                    <div>
                        <ButtonComponent
                            button={{
                                ...cta,
                                detached: true,
                                className: style.btn,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default BannerCTA;
